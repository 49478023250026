/* eslint-disable */
import * as React from 'react';
export interface NavArrowLeftProps extends React.SVGAttributes<SVGElement> {
  size?: string;
}
export const NavArrowLeft: React.SFC<NavArrowLeftProps> = ({size, className, ...props}) => (
  <svg
    width="12px"
    height="24px"
    viewBox="0 0 12 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>09 Icons / Arrows / Navigation / RegularArrow / Medium / Left / CenterAlign</title>
    <g id="Slider-Gallery-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="slider-gallery---arrows" transform="translate(-57.000000, -247.000000)" className={className} >
        <g id="🎨-Color" transform="translate(51.000000, 247.000000)">
          <polygon
            transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(90.000000) translate(-12.000000, -12.000000) "
            points="11.9989984 6 0 16.4818792 1.31408063 18 11.9989984 8.66778523 22.6859194 18 24 16.4818792"></polygon>
        </g>
      </g>
    </g>
  </svg>
);
NavArrowLeft.displayName = 'NavArrowLeft';
/* tslint:enable */
/* eslint-enable */
